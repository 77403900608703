import React from 'react'
import PageWithLayout from '../../Layouts/PageWithLayout'

const ThankPage = () => {

    const styles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    }

    const h1 = {
        textAlign: 'center',
        margin: '3rem 0 1rem 0'
    }

    return (
        <PageWithLayout>
            <div style={styles} className="thank-you">
                <h1 style={h1}>Thank you for leaving a message</h1>
                <p>We will respond as soon as possible.</p>
            </div>
        </PageWithLayout>
    )
}

export default ThankPage
