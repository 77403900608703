import { gql, useMutation } from '@apollo/client';
import React, {useState} from 'react';
import PageWithLayout from '../../Layouts/PageWithLayout';
import LoadingPage from '../Services/LoadingPage';
import ThankPage from './ThankPage';

const ContactPage = () => {

    const [data, setData] = useState({
        name: '',
        email: ''
    });

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value 
        });
    }

    const CREATE_CONTACT = gql `
    mutation CreateContact {
        createContact(data: {
          name: "${data.name}",
          email: "${data.email}",
          message: "${data.message}"
        })
        {
          name,
          email,
          message
        }
      }
    `

    const [createContact, {data: res, loading, error}] = useMutation(CREATE_CONTACT);
    if(loading) return <LoadingPage />;
    if(error) return 'ERROR!';
    let input;

    if(!res) {
        return (
            <PageWithLayout>
                <span className="col-12 contactTitle">
                    <h1>Say hi!</h1>
                </span>
    
                <form className="col-12" onSubmit={e => {
                    e.preventDefault();
                    createContact();
                    input.value = ''
                }}>
                    <span>
                        <label>Name</label>
                        <input onChange={handleChange} ref={node => {input = node}} type="text" name="name" value={data.name} />
                    </span>
                    <span>
                        <label>E-mail</label>
                        <input onChange={handleChange} ref={node => {input = node}} type="email" name="email" value={data.email} />
                    </span>
                    <span>
                        <label>Message</label>
                        <textarea onChange={handleChange} ref={node => {input = node}} name="message" value={data.message} />
                    </span>
    
                <span>
                    <button type="submit" className="btn">
                        Send
                    </button>
                </span>
    
                </form>
            </PageWithLayout>
        )
    }

    return <ThankPage />    
}

export default ContactPage;
