import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {routes} from './core/routes';
import ArtistPage from './Pages/ArtistPage';
import ArtistDetail from './Pages/ArtistPage/Detail/ArtistDetail';
import ArtworkPage from './Pages/ArtworkPage';
import ArtworkDetail from './Pages/ArtworkPage/Detail/ArtworkDetail';
import ContactPage from './Pages/ContactPage';
import HomePage from './Pages/HomePage';
import AboutPage from './Pages/RichPage/AboutPage';
import { NotFoundPage } from './Service/Error';

const Routes = () => {
    return (
        <Switch>
            <Route exact path={routes.Home} component={HomePage} />
            <Route exact path={routes.Contact} component={ContactPage} />
            <Route exact path={routes.Artists} component={ArtistPage} />
            <Route path={routes.ArtistDetail} component={ArtistDetail} />
            <Route exact path={routes.Artworks} component={ArtworkPage} />
            <Route path={routes.ArtworkDetail} component={ArtworkDetail} />
            <Route path={routes.About} component={AboutPage} />
            {/* Show NotFoundPage when no route is found */}
            <Route path="*" component={NotFoundPage} />
        </Switch>
    )
}

export default Routes;
