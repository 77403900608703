import { gql } from '@apollo/client';

const getArtists = gql `query getArtists
{

      artists {
        id
        biography {
          html
        }
        createdAt
        firstName
        lastName
        image {
          handle
          fileName
          url
          id
        }
        slug
        website
        artworks {
          description {
            html
          }
          id
          image {
            id
            handle
            fileName
            url
          }
          physicalProduct
          price
          purchaseUrl
          quantity
          richImages {
            fileName
            handle
            id
            url
          }
          slug
          title
        }

    
  }
}`;

const getArtistBySlug = (slug) => {
    const query = gql `query getArtist
    {
        artist(where: {slug: "${slug}"}) {
            id
            slug
            firstName
            lastName
            artistName
            website
            biography {
              html
            }
            image {
              id
              url
              fileName
            }
            artworks {
              id
              slug
              title
              stage
              purchaseUrl
              createdAt
              updatedAt
              image {
                id
                url
                fileName
              }
            }
            stage
            createdAt
            updatedAt
        }
    }`

    return query;
};

export {
    getArtists,
    getArtistBySlug,
}