import React from 'react';
import { Link } from 'react-router-dom';
import { route, routes } from '../../core/routes';
import { UseQuery } from '../../core/utils';
import { getArtworks } from '../../core/utils/queries/Artwork';
import PageWithLayout from '../../Layouts/PageWithLayout';
import LoadingPage from '../Services/LoadingPage';
import styles from './artwork.module.css';

const ArtworkPage = () => {


    const { data } = UseQuery(getArtworks);
    if (data === undefined) return <LoadingPage />

    const {artworks} = data;
    console.log(artworks);
    return (
        <PageWithLayout>
            <section className={styles.artworkContainer}>
                <h1 className="jumbo">Don't say <span className="primary">NFT</span>,<br/>we prefer ART</h1>
                <div className={styles.artworkCollection}>
                    
                    {artworks.map((i) => (
                        <Link key={i.id} to={route(routes.ArtworkDetail, { slug: i.slug })}>
                            <div className={styles.artworkBox}>
                                <img className={styles.artworkBoxImg} src={i.image.url} alt={i.image.fileName} />
                            </div>
                            <span className={styles.artworkContent}>
                                <h2>{i.title.substr(0, 17)}
                                {i.title.length > 18 && <em>...</em>}
                                </h2>
                                <small>{i.physicalProduct ? 'NFT + Print' : 'NFT Only'}</small>
                            </span>
                        </Link>
                    ))}

                </div>
            </section>
        </PageWithLayout>
    )
}

export default ArtworkPage;
