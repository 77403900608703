import React from 'react';

const LoadingPage = () => {
    return (
        <div id="Loading">
         <h1>Loading...</h1>   
        </div>
    )
}

export default LoadingPage;
