import { gql } from '@apollo/client';

const getArtworks = gql `query getArtworks
{
    artworks {
        id
        slug
        title
        purchaseUrl
        physicalProduct
        description {
            html
        }
        price
        quantity
        image {
            id
            url
            fileName
        }
        artist {
          id
          slug
          slug
          artistName
          firstName
          lastName
        }
        createdAt
      }
}`;

const getArtworkBySlug = (slug) => {
    const query = gql `query getArtwork
    {
        artwork(where: {slug: "${slug}"}) {
            id
            slug
            title
            purchaseUrl
            physicalProduct
            price
            size
            description {
                html
            }
            richImages {
                id
                url
                fileName
            }
            quantity
            image {
            id
            url
            fileName
            }
            artist {
            id
            slug
            website
            image {
                id
                url
                fileName
            }
            firstName
            lastName
            artistName
            artworks {
                id
                slug
                title
                image {
                id
                url
                fileName
                }
            }
            }
            createdAt
        }
    }`

    return query;
};

export {
    getArtworks,
    getArtworkBySlug,
}