// import { useQuery } from '@apollo/client';
import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import PageWithLayout from '../../Layouts/PageWithLayout';
import styles from './home.module.css';
import {routes} from '../../core/routes';

const HomePage = () => {

    const [timerDays, setTimerDays] = useState(10);
    const [timerHours, setTimerHours] = useState(11);
    const [timerMinutes, setTimerMinutes] = useState(12);
    const [timerSeconds, setTimerSeconds] = useState(13);

    let interval;

    const startTimer = () => {
        const countDownDate = new Date("January 20, 2022 12:00:00").getTime();

        interval = setInterval(() => {
            const now = new Date().getTime();
            const distance = countDownDate - now;

            const days = Math.floor(distance / (24*60*60*1000));
            const hours = Math.floor(distance % (24*60*60*1000) / (1000*60*60));
            const minutes = Math.floor(distance % (60*60*1000) / (1000*60));
            const seconds = Math.floor(distance % (60*1000) / (1000));

            if(distance<0) {
                clearInterval(interval.current);
            } else {
                // Update Timer
                setTimerDays(days);
                setTimerHours(hours);
                setTimerMinutes(minutes);
                setTimerSeconds(seconds);
            }
        })
    };

    useEffect(() => {
        startTimer();
    })
    
    return (
        <PageWithLayout>
            <main className={styles.main}>
                <h1 className="jumbo center">Where art &amp;<br/>digital connect</h1>
                
                <section className={styles.cta}>
                    <Link className="btn" to={routes.Artworks}>
                        Explore collection
                    </Link>
                    <Link className="link" to={routes.Contact}>
                        Contact our founder
                    </Link>
                </section>
            </main>

            {/* <div className={styles.countdown}>
                <h1>Launching first NFT in</h1>
                <div className={styles.countdownContainer}>
                    <div className={styles.countdownDay}>
                        <h3 className="day">{timerDays}</h3>
                        <h3>Days</h3>
                    </div>
                    <div className={styles.countdownHour}>
                        <h3  className="hour">{timerHours}</h3>
                        <h3>Hours</h3>
                    </div>
                    <div className={styles.countdownMinutes}>
                        <h3 className="minute">{timerMinutes}</h3>
                        <h3>Minutes</h3>
                    </div>
                    <div className={styles.countdownSeconds}>
                        <h3 className="second">{timerSeconds}</h3>
                        <h3>Seconds</h3>
                    </div>
                </div>
            </div> */}

            <section className="contentSection">
                <h1>Our name</h1>
                <p>So, why ‘Enygma’? According to our beloved Cambridge dictionary, an enigma (mind the i) “is something that is mysterious and seems impossible to understand”, NFT’s are for a lot of people still fairly new technology, so it seemed like a logical choice. The Enigma also was a machine used in the mid-20th century to protect diplomatic and military communication. Hence the link to our NFT-gallery and blockchain technology which is up to today the most advanced one can find.</p>
                <h1>What are NFT’s?</h1>
                <p>NFT’s, also called ‘non fungible tokens’, are a form of unique data based on blockchain- technology. NFT’s are often used to give digital files such as photos, videos, audio, etc. a public proof of ownership, in essence a certificate of authenticity. NFT’s can be publicly sold and traded on a marketplace, for instance <a className="link" href="https://opensea.io/" rel="noreferrer" target="_blank" >opensea.io</a>.</p>
                <h1>Enygma phase 1</h1>
                <p>At the moment we are helping creators of real-life artworks to sell their products as an NFT. That way they can create a new way of interacting with their customers and with each other. Our purpose is to unburden you as an artist and help you discover the new world of NFT’s. We set up all important tasks for you, your wallet, your account, we mint your NFT’s.  We explain everything to you, from gas fees up to Metamask.</p>
                <h1>Enygma phase 2</h1>
                <p>Creating a fully developed social platform where NFT’s can be publicly looked at, traded and sold. Users can see which NFT’s are owned by whom. Artist can add their own NFT’s on the platform and, if they want to, set them up for sale. The idea is to create a socially interactive platform where users can genuinely connect with one another through NFT’s. Creators of physical artworks can use the blockchain network to discover an entirely new way to value their work. They can create a new way of interacting with their customers and with each other. Enygma aspires to form a bridge of mutual support between artists and collectors. We are here for every ‘old-school’ artist to discover and enjoy the new ways of digitally expressing oneself in the wonderful world of NFT’s. We are here for every ‘early-adopter’ who would still like a physical piece of art with his newly acquired NFT. We are here of every lost piece of art that can find a new life through the blockchain network. In other words: We are here for you.</p>
            </section>

            <section className="cta">
                <h1 className="color-white">Ready to be part of the future?</h1>
                <span className="cta-btn">
                    <Link to={routes.Contact} className="btn">Start now!</Link>
                </span>
            </section>
        </PageWithLayout>
    )
}

export default HomePage;
