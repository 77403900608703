import React from 'react'
import Routes from './Routes'

const AppContainer = () => {
    return (
        <Routes />
    )
}

export default AppContainer
