const routes = {
    'Home': `/`,
    'Contact':  `/contact`,
    'Artists': `/artists`,
    'ArtistDetail': `/artists/:slug`,
    'Artworks': `/artworks`,
    'ArtworkDetail': `/artworks/:slug`,
    'About': `/about`
};

const route = (path, options = {}) => {
    Object.keys(options).forEach(key => {
        path = path.replace(`:${key}`, options[key]);
    });
    return path;
}

export {
    routes,
    route
}