import React from 'react';
import AppContainer from './AppContainer';
import './App.css';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';

//
const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHCMS_URL,
  cache: new InMemoryCache(),
  headers: {
    Authorization: `BEARER ${process.env.REACT_APP_GRAPHCMS_TOKEN}`
  }
});

function App() {
  return (
    <ApolloProvider client={client}>
      <AppContainer />
    </ApolloProvider>
  );
}

export default App;
