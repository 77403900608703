import React from 'react';
import { Link } from 'react-router-dom';
import styles from './header.module.css';
import {routes} from '../../core/routes';
import { gql, useQuery } from '@apollo/client';
import LoadingPage from '../../Pages/Services/LoadingPage';
import { NotFoundPage } from '../../Service/Error';

const headerNavigation = [
    {
        label: "Collection",
        url: routes.Artworks
    },
    {
        label: "Artists",
        url: routes.Artists
    },
    {
        label: "Contact",
        url: routes.Contact
    },
];

const Header = () => {

    const iconQuery = gql `
    {
        assets(where: {fileName: "logo.png"}) {
            id
            url
                fileName    
          }
    }`;
    const { data, loading, error } = useQuery(iconQuery);
    if(loading) return <LoadingPage />
    if(error) return <NotFoundPage />

    const { assets } = data;
    return (
        <header className={styles.header}>
            <Link to={routes.Home}>
                {assets.length !== 0 ? <img src={assets[0].url} alt="Enygma Logo" /> : <p>Enygma</p>}
            </Link>

            <ul>
                {headerNavigation.map((i, index) => (
                    <Link key={index} to={i.url}>
                        <li>{i.label}</li>
                    </Link>
                ))}
            </ul>
        </header>
    )
}

export default Header;
