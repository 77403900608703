import React, {useState} from 'react';
import { Link, useParams } from 'react-router-dom';
import PageWithLayout from '../../../Layouts/PageWithLayout';
import LoadingPage from '../../Services/LoadingPage';
import { UseQuery } from '../../../core/utils';
import { getArtworkBySlug } from '../../../core/utils/queries/Artwork';
import style from '../artwork.module.css';
import { route, routes } from '../../../core/routes';

const ArtworkDetail = () => {

    const [scale, setScale] = useState(false);
    const [showImages, setShowImages] = useState(false);
    const { slug } = useParams();

    const { data } = UseQuery(getArtworkBySlug(slug));
    if (data === undefined) return <LoadingPage />

    const {artwork} = data;

    console.log(artwork);

    const returnToCollection = () => {
        window.history.back();
    }

    const clickHandler = (img) => {
        if(scale === false) {
            setScale(true);
            console.log(img);
            localStorage.setItem('url', img.url);
            localStorage.setItem('fileName', img.fileName);

        } else {
            setScale(false);
            localStorage.removeItem('url');
            localStorage.removeItem('fileName');
        }

    }

    const ScaledImage = (image) => {

        return (
            <div id={style.scaledImage}>
                <span className={style.closeButton}>
                <p onClick={clickHandler} className="link closeBtn">
                    Close
                </p>
                </span>

                <img id={style.scalerImage} src={localStorage.getItem('url')} alt={localStorage.getItem('fileName')} />
            </div>
        )
    } 

    return (
        <PageWithLayout>
            {scale === true && <ScaledImage />}

            <span className="returnLink">
                <p className="link" onClick={returnToCollection}>Return</p>
            </span>
            <section className={style.detailContainer}>
                <img className={style.detailImg} src={artwork.image.url} alt={artwork.image.fileName} />
                <div className={style.detailContent}>
                    <span>
                        <h1 className="jumbo">{artwork.title}</h1>
                        <small>By <Link className="link" to={route(routes.ArtistDetail, {slug: artwork.artist.slug})}>{artwork.artist.firstName} {artwork.artist.lastName}</Link> — </small>
                        <small className="col-12">
                            <a className="link" target="_blank" rel="noreferrer" href={artwork.artist.website} >visit website</a>
                        </small>
                    </span>
                    {artwork.description && (
                        <div dangerouslySetInnerHTML={{__html: artwork.description.html}}></div>
                    )}                
                </div>
                <div>
                </div>
            </section>
            <div className={style.detailInfo}>
                <table>
                    <thead>
                        <tr>
                            <th>Artist</th>
                            <th>Size</th>
                            <th>Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>{artwork.artist.firstName} {artwork.artist.lastName}</th>
                            <th>{artwork.size}</th>
                            <th>{artwork.physicalProduct ? 'NFT + Print' : 'NFT only'}</th>
                        </tr>
                    </tbody>
                </table>
                <div className="flex">
                    <span className="col-6">
                    </span>
                    <span className="col-6 flexEnd flexCol">
                        <h4>€{artwork.price}</h4>
                        {artwork.purchaseUrl ? (
                            <a className="btn" href={artwork.purchaseUrl} target="_blank" rel="noreferrer">
                                Bid now
                            </a>
                        ) : (
                            <p>Coming soon</p>
                        )}

                    </span>
                </div>

                {showImages ? (
                <div className="image-collection">
                    {artwork.richImages.map((img) => (
                    <div key={img.id} className="imageBox">
                        <img onClick={() => {
                            setScale(true)
                            clickHandler(img);
                        }} className="imageBoxImg" src={img.url} alt={img.fileName} />
                        
                    </div>  
                    ))}
                </div>
                ) : (
                <span className="col-12 center">
                    <p id="ShowMore" className="link" onClick={() => setShowImages(true)}>Show all images</p>
                </span>
                )}

            </div>
        </PageWithLayout>
    )

}

export default ArtworkDetail;
