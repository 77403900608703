import React, {useState} from 'react';
import { Link, useParams } from 'react-router-dom';
import { UseQuery } from '../../../core/utils';
import { getArtistBySlug } from '../../../core/utils/queries/Artist';
import PageWithLayout from '../../../Layouts/PageWithLayout';
import LoadingPage from '../../Services/LoadingPage';
import style from '../../ArtworkPage/artwork.module.css';
import { route, routes } from '../../../core/routes';

const ArtistDetail = () => {

    const [showWork, setShowWork] = useState(false);
    const {slug} = useParams();
    
    const { data } = UseQuery(getArtistBySlug(slug));
    if (data === undefined) return <LoadingPage />

    const {artist} = data;
    console.log(artist);

    const returnToCollection = () => {
        window.history.back();
    }

    return (
        <PageWithLayout>
            <span className="returnLink">
                <p className="link" onClick={returnToCollection}>Back to artists</p>
            </span>
            <section className={style.detailContainer}>
                <img className={style.detailImg} src={artist.image.url} alt={artist.image.fileName} />
                <div className={style.detailContent}>
                    <span>
                        <h1 className="jumbo">{artist.firstName} {artist.lastName}</h1>
                        {/* <small>{artist.artistName}</small> */}
                        <small className="col-12">
                            <a className="link" target="_blank" rel="noreferrer" href={artist.website} >visit website</a>
                        </small>
                    </span>
                    {artist.biography && (
                        <p dangerouslySetInnerHTML={{__html: artist.biography.html}}></p>
                    )}
                    
                </div>
                {showWork ? (
              <div className="featuredWork">
              <p>Featured work</p>
              <div className="featuredCollection">
                  {artist.artworks.map((i, index) => {
                      return index < 6 && (
                          <div key={i.id} className="imageBox">
                              <Link to={route(routes.ArtworkDetail, {slug: i.slug})}>
                                  <img className="imageBoxImg" src={i.image.url} alt={i.image.fileName} />
                                  <h3>{i.title}</h3>
                              </Link>
                          </div>
                      )
                  })}
              </div>
          </div>
                ) : (
                <span className="col-12 center">
                    <p id="ShowMore" className="link" onClick={() => setShowWork(true)}>Show more work by {artist.firstName} {artist.lastName}</p>
                </span>
                )}
  
            </section>
        </PageWithLayout>
    )
}

export default ArtistDetail;
