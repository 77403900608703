import { useQuery } from '@apollo/client';
import LoadingPage from '../../Pages/Services/LoadingPage';
import { NotFoundPage } from '../../Service/Error';

/**
 * @create ServicePages for Loading & Error
 */

const UseQuery = (query) => {
    const { data, loading, error } = useQuery(query);
    if (loading) return <LoadingPage />
    if (error) return <NotFoundPage />;
    return {data};
}

export {
    UseQuery,
}