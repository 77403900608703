import React from 'react';
// import Footer from './Footer';
import Header from './Header';
import styles from './layout.module.css';

const PageWithLayout = ({children}) => {
    return (
        <>
            <Header/>

            <main className={styles.container}>
                {children}
            </main>
            
            {/* <Footer /> */}
        </>
    )
}

export default PageWithLayout;
