import React from 'react';
import { Link } from 'react-router-dom';
import { route, routes } from '../../core/routes';
import { UseQuery } from '../../core/utils';
import { getArtists } from '../../core/utils/queries/Artist';
import PageWithLayout from '../../Layouts/PageWithLayout';
import LoadingPage from '../Services/LoadingPage';
import styles from './artists.module.css';

const ArtistPage = () => {

    const { data } = UseQuery(getArtists);
    console.log(data);
    if (data === undefined) return <LoadingPage />

    const {artists} = data;
    console.log(artists);
    return (
        <PageWithLayout>
            <section className={styles.artistsContainer}>
                <div className="col-12 center">
                    <h1 className="jumbo">Meet our artists</h1>
                </div>
                <div className={styles.artistCollection}>

                    {artists.map((i) => (
                        <Link key={i.id} to={route(routes.ArtistDetail, { slug: i.slug })}>
                            <div className={styles.artworkBox}>
                                <img className={styles.artworkBoxImg} src={i.image.url} alt={i.image.fileName} />
                            </div>
                            <span className="center">
                                {i.artistName ? (
                                  <h1>{i.artistName}</h1>
                                ) : (
                                  <h1>{i.firstName} {i.lastName}</h1>
                                )}
                            </span>
                        </Link>
                    ))}
                </div>
            </section>
        </PageWithLayout>
    )
}

export default ArtistPage;
