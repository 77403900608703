import React from 'react';
import './index.css';

const NotFoundPage = () => {
    return (
        <>
         <p>Not found Page</p>   
        </>
    )
}

export default NotFoundPage;
