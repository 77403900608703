import React from 'react'
import PageWithLayout from '../../Layouts/PageWithLayout'
import styles from './rich.module.css'

const AboutPage = () => {

    return (
        <PageWithLayout>
            <section className={styles.header}>
                <h1>About Enygma</h1>
            </section>
            <section className={styles.body}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
            Duis auctor sed eros quis volutpat. 
            Proin malesuada rutrum libero, ac dapibus magna lobortis a. 
            Sed id est lacinia, venenatis turpis eu, varius velit. 
            Maecenas eu augue et ante dignissim tristique. Sed id consequat nibh. 
            Mauris pharetra hendrerit felis, laoreet sodales nisl viverra eu. 
            Duis rutrum elementum porttitor. Nulla a nunc dui. 
            Etiam libero lectus, condimentum at tellus eget, scelerisque fermentum diam. 
            Morbi gravida suscipit odio eget tincidunt.
            </section>
        </PageWithLayout>
    )
}

export default AboutPage
